@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
  height: 100%
  
}

body{
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;

}

h2{
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 500;
}

#root {
  height: 100%
}

summary::-webkit-details-marker {
  display: none;
}

.mapboxgl-popup-close-button {
  padding: 4px 8px;
}